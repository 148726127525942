import React from "react"
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Box,
  useTheme,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HowToGetLicense from "./HowToGetLicense"
import { useTranslation } from "react-i18next"
import { useAnalytics } from "components/useAnalytics"
import { LicensingStepType } from "config/courseLandingPages"

type Props = {
  state?: string
  path?: LicensingStepType
  title?: string
  subTitle?: string
  highlightId?: string
  control?: Array<"path" | "state">
}

const HowToGetLicenseAccordion = ({
  state,
  path,
  title,
  subTitle,
  highlightId,
  control,
}: Props): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { track } = useAnalytics()

  return (
    <Accordion
      sx={{ textAlign: "center" }}
      onChange={(_, expanded) => {
        if (expanded) {
          track("How To Get License Section Opened")
        }
      }}
    >
      <AccordionSummary
        sx={{ background: theme.palette.secondary.main }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {t("Not sure how to get your license")}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ ml: 2, borderRadius: 4 }}
          >
            {`${t("Expand to View")}`}
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h4" align="center" gutterBottom>
            <strong>{title || t("Steps to Getting Your License")}</strong>
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            {subTitle ||
              `${t(
                "Use this guide to make sure you are in the right place in your licensing journey",
              )}.`}
          </Typography>
          <HowToGetLicense
            usState={state}
            controlsToShow={control}
            path={path}
            highlightId={highlightId}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default HowToGetLicenseAccordion
