import { ID } from "interfaces"

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
} from "@mui/material"
import { gql, useQuery } from "@apollo/client"
import { GQLStateConfig } from "generated/documentTypes"

const GET_STATE_CONFIGS = gql`
  query GetStateConfigs($pageQuery: PageQuery) {
    stateConfigs(pageQuery: $pageQuery) {
      edges {
        node {
          state
          longName
        }
      }
    }
  }
`

type Edge = {
  node: GQLStateConfig
}

export interface StateSelectProps {
  setSelected: (newValue: ID) => void
  selected?: ID
  hasShowAllOption?: boolean
}

const StateSelect: React.FC<StateSelectProps> = ({
  setSelected,
  selected,
  hasShowAllOption = false,
}) => {
  const { loading, data } = useQuery(GET_STATE_CONFIGS, {
    variables: { pageQuery: { first: 50, skip: 0 } },
  })

  const sortedEdges = data?.stateConfigs.edges.sort((a: Edge, b: Edge) => {
    if (a.node.state < b.node.state) return -1
    if (a.node.state > b.node.state) return 1
    return 0
  })

  if (loading) {
    return <Skeleton variant="rectangular" width="400px" height={56} />
  }

  return (
    <FormControl sx={{ maxWidth: 400 }} fullWidth>
      <InputLabel id="state-select">State</InputLabel>
      <Select
        placeholder="Select a state"
        label="State"
        labelId="state-select"
        value={selected || ""}
        data-cy="us-state-select"
      >
        {hasShowAllOption && (
          <MenuItem
            value={""}
            onClick={() => setSelected("")}
            data-cy="state-menu-item"
          >
            {"Show All"}
          </MenuItem>
        )}
        {sortedEdges?.map((edge: Edge) => (
          <MenuItem
            key={edge.node.state}
            value={edge.node.state}
            onClick={() => setSelected(edge.node.state)}
            data-cy="state-menu-item"
          >
            {edge.node.longName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StateSelect
