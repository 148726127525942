import TitleSection from "./TitleSection"

type Props = {
  children: React.ReactNode
  title: string
}

const LinearGradientBox = ({ children, title }: Props): React.ReactElement => (
  <TitleSection
    title={title}
    style={{
      backgroundImage:
        "linear-gradient(180deg, hsl(337, 100%, 72%) 0%, hsl(295, 62%, 71%) 29%, hsl(229, 100%, 78%) 43%, hsl(196, 100%, 50%) 57%, hsl(189, 100%, 47%) 71%, hsl(175, 90%, 43%) 100%)",
      color: "primary.contrastText",
    }}
  >
    {children}
  </TitleSection>
)

export default LinearGradientBox
