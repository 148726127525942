import React from "react"
import { useTheme } from "@mui/material/styles"

import { Avatar } from "@mui/material"
import RoundedCard from "./RoundedCard"

// A small group of text with an icon used for listing features.

interface IconTextBlockProps {
  icon?: JSX.Element
  children?: React.ReactNode
  title: string
}

const IconTextBlock = ({
  icon,
  title,
  children,
}: IconTextBlockProps): JSX.Element => {
  const theme = useTheme()

  return (
    <RoundedCard>
      {icon ? (
        <Avatar sx={{ bgcolor: theme.palette.secondary.dark }}>{icon}</Avatar>
      ) : null}
      <h3
        style={{
          color: theme.palette.primary.main,
          marginBottom: 5,
          marginTop: 12,
        }}
      >
        {title}
      </h3>
      <div>{children}</div>
    </RoundedCard>
  )
}
IconTextBlock.defaultProps = {
  link: null,
  linkText: null,
  text: null,
  icon: null,
}

export default IconTextBlock
