import { Box, Container, Typography } from "@mui/material"

type Props = {
  title: string
  children: React.ReactNode
  style?: React.CSSProperties
}

const TitleSection = ({
  children,
  title,
  style,
}: Props): JSX.Element | null => {
  return (
    <Box component="section" sx={{ ...style, py: 4 }}>
      <Container maxWidth="xl">
        <Typography variant="h4" align="center" gutterBottom>
          <strong>{title}</strong>
        </Typography>
        {children}
      </Container>
    </Box>
  )
}

export default TitleSection
