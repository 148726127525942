import React from "react"
import Link from "next/link"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material"
import { LicensingStep } from "./HowToGetLicenseContent"
import { useTranslation } from "react-i18next"
import { useAnalytics } from "components/useAnalytics"

type ViewProps = {
  steps: LicensingStep[]
  highlightId?: string
  articleLink?: string
  articleLinkColor?: string
}

const HowToGetLicenseView = ({
  steps,
  highlightId,
  articleLink,
  articleLinkColor,
}: ViewProps): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { track } = useAnalytics()

  const trackStepClick = (stepId: string): void => {
    track("Licensing Section Step Clicked", { stepId })
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        {steps.map((step, i) => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={step.id}>
            <Card
              className={step.id === highlightId ? "top-accent" : undefined}
              data-cy="how-to-get-license-step"
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar
                    sx={{
                      height: 24,
                      width: 24,
                      backgroundColor: theme.palette.primary.main,
                      mr: 1,
                    }}
                  >
                    {i + 1}
                  </Avatar>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", lineHeight: 1.1 }}
                  >
                    {step.title}
                  </Typography>
                </Box>
                {step.id === highlightId && (
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {`${t("You are here")}!`}
                  </Typography>
                )}
                <Typography variant="body2">{step.description}</Typography>
              </CardContent>
              {step.link && step.id !== highlightId ? (
                <CardActions>
                  {step.link.startsWith("http") ? (
                    <Button
                      size="small"
                      href={step.link}
                      target="_blank"
                      rel="noreferrer"
                      variant="outlined"
                      onClick={() => trackStepClick(step.id)}
                    >
                      {step.linkText}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      component={Link}
                      href={step.link}
                      variant="outlined"
                      onClick={() => trackStepClick(step.id)}
                    >
                      {step.linkText}
                    </Button>
                  )}
                </CardActions>
              ) : null}
            </Card>
          </Grid>
        ))}
      </Grid>
      {articleLink && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          {`${t("For more licensing details")}, `}
          <Link
            href={articleLink}
            style={{ color: articleLinkColor ?? "inherit" }}
          >
            <u>{t("visit our help article")}</u>
          </Link>
          .
        </Box>
      )}
    </>
  )
}

export default HowToGetLicenseView
